import React from "react"
import { Link } from "gatsby"
import { Navbar, Nav } from "react-bootstrap"
import { FaFacebookSquare, FaInstagram } from "react-icons/fa"

const CustomNavbar = ({ pageInfo }) => {
  return (
    <>
      <div id="nav-topper"></div>
	  <Navbar variant="dark" expand="lg" id="site-navbar">
        {/* <Container> */}
        <Link to="/" className="link-no-style">
          <Navbar.Brand as="span"></Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto" activeKey={pageInfo && pageInfo.pageName}>
		  <Link to="/" className="link-no-style">
              <Nav.Link as="span" eventKey="index">
                Home
              </Nav.Link>
            </Link>
			<Link to="/skincare" className="link-no-style">
              <Nav.Link as="span" eventKey="/skincare/">
                Skincare
              </Nav.Link>
            </Link>
            <Link to="/waxing-services-for-men" className="link-no-style">
              <Nav.Link as="span" eventKey="/waxing-services-for-men/">
                Waxing for Men
              </Nav.Link>
            </Link>
			<Link to="/waxing-services-for-women" className="link-no-style">
              <Nav.Link as="span" eventKey="/waxing-services-for-women/">
                Waxing for Women
              </Nav.Link>
            </Link>
			<Link to="/schedule-appointment" className="link-no-style">
              <Nav.Link as="span" eventKey="Schedule an Appointment">
                Schedule
              </Nav.Link>
            </Link>
			<Link to="/faq" className="link-no-style">
              <Nav.Link as="span" eventKey="/faq/">
                FAQ
              </Nav.Link>
            </Link>
			<Nav.Link className="mobile-dropdown-phone" as="span">
				<a href="tel:123-456-7890">(919) 623-0361</a>
			</Nav.Link>
			<span className="navbar-social">
				<a href="https://www.facebook.com/fullmoonwaxing/"><FaFacebookSquare className="mr-2"/></a><a href="https://www.instagram.com/fullmoonwaxingnc/"><FaInstagram className="mr-2"/></a>
			</span>

          </Nav>
        </Navbar.Collapse>
        {/* </Container> */}
      </Navbar>
    </>
  )
}

export default CustomNavbar
