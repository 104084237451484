/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import icon from "../images/icon.png"

function SEO({ description, lang, meta, keywords, title }) { 
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )

  const logoURL = site.siteMetadata.siteUrl + '/full-moon-logo.png';

  const metaDescription = description || site.siteMetadata.description

  const schemaOrgWebPage = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    url: 'https://fullmoonwaxing.com',
    inLanguage: 'en',
    mainEntityOfPage: 'https://fullmoonwaxing.com',
    description: description,
    name: title,
    type: 'HealthAndBeautyBusiness',
    provider: {
      type: 'LocalBusiness',
      image: logoURL,
      name: 'Full Moon Waxing',
      telephone: '(919) 623-0361',
      openingHours: 'We 10:00-19:00, Th 10:00-19:00, Fr 10:00-17:00, Sa 13:00-16:00',
      priceRange: '$$',
      address: {
        type: 'PostalAddress',
        addressLocality: 'Raleigh',
        addressRegion: 'NC',
        postalCode: '27615',
        streetAddress: '154 Mine Lake Ct.',
      },
      areaServed: {
        type: 'City',
        name: 'Raleigh',
      },
    },
    author: {
      '@type': 'Person',
      name: 'Sarah Mace',
    },
    copyrightYear: new Date().getFullYear(),
    datePublished: '2020-01-01T10:30:00+01:00',
    dateModified: new Date(),
    image: logoURL,
    address: {
      type: 'PostalAddress',
      addressLocality: 'Raleigh',
      addressRegion: 'NC',
      postalCode: '27615',
      streetAddress: '154 Mine Lake Ct.',
    },
    priceRange: '$$',
    telephone: '(919) 623-0361',
  }
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
	  titleTemplate={`%s | ${site.siteMetadata.title}`}
	  link={[
        { rel: 'icon', type: 'image/png', sizes: "16x16", href: `${icon}` },
        { rel: 'icon', type: 'image/png', sizes: "32x32", href: `${icon}` },
        { rel: 'shortcut icon', type: 'image/png', href: `${icon}` },
    	]}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    >
      <script type="application/ld+json">{JSON.stringify(schemaOrgWebPage)}</script>

    </Helmet>

  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO
